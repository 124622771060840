// import _map from 'lodash/map.js'

export default function (tracker) {
    const config = useRuntimeConfig()

    let id = ''
    if (config.public.env === 'production') {
        id = 'GTM-P2VD8RR'
    } else {
        id = 'GTM-KKBN2HV'
    }

    /*
    list of events:
    page                                page_view

    zip-code.check                      search
    zip-code.check.success              search_success*
    zip-code.check.failed               search_failed*
    
    order.start                         begin_checkout
    order.add-to-cart                   add_to_cart
    order.confirmation                  checkout_progress*
    order.finished                      purchase
    order.cancelled                     checkout_aborted*
    order.leave-message                 checkout_leave_message*

    user.login                          login
    user.registered                     sign_up

    */

    tracker.add({
        name: 'googleTagManager',
        level: 'tracking',
        type: 'text/plain',
        'data-usercentrics': 'Google Tag Manager',
        settings: [
            {
                code: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${id}');`,
            },
        ],
        callback() {
            tracker.listen('zip-code.check', (event, data) => {
                const state = event.split('.').pop()
                const eventName =
                    state === 'check' ? 'search' : 'search_' + state

                dataLayer.push({
                    event: eventName,
                    search_term: data.zipCode + ' ' + data.city,
                    zipCode: data.zipCode,
                    customerGroup: data.customerGroup,
                })
            })

            tracker.listen('order.start', (_event, data) => {
                dataLayer.push({ event: 'begin_checkout', ...data })
            })

            tracker.listen('order.add-to-cart', (_event, data) => {
                dataLayer.push({ event: 'add_to_cart', ...data })
            })

            tracker.listen('order.confirmation', (_event, data) => {
                dataLayer.push({ event: 'checkout_progress', ...data })
            })

            tracker.listen('order.finished', (_event, data) => {
                dataLayer.push({
                    event: 'purchase',
                    ...data,
                    value: data.total,
                    currency: 'EUR',
                })
            })

            tracker.listen('order.cancelled', (_event, data) => {
                dataLayer.push({ event: 'checkout_aborted', ...data })
            })

            tracker.listen('order.leave-message', (_event, data) => {
                dataLayer.push({ event: 'checkout_leave_message', ...data })
            })

            tracker.listen('user.login', (_event, data) => {
                dataLayer.push({ event: 'login', ...data })
            })

            tracker.listen('user.registered', (_event, _data) => {
                dataLayer.push({ event: 'sign_up' })
            })
        },
    })
}
